import { Fragment } from 'react'
import menus from '../../assets/data/menus'
import { useLocation } from 'react-router-dom'
import HeaderMenu from '../HeaderMenu'
import Footer from '../Footer'
import MenuContent from '../MenuContent'

const Menu = () => {
  const { search } = useLocation()
  const idRestaurant = new URLSearchParams(search).get('id')
  const data = menus.filter(
    (restaurant) => restaurant.id.toString() === idRestaurant
  )[0]

  return (
    <Fragment>
      <HeaderMenu />
      <MenuContent data={data} />
      <Footer />
    </Fragment>
  )
}

export default Menu
