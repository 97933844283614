import { Fragment } from 'react'
import '../../assets/styles/style.css'
import Footer from '../Footer'
import Functioning from '../Functioning'
import Header from '../Header'
import Loader from '../Loader'
import Location from '../Location'
import Restaurants from '../Restaurant'

const Home = () => {
  return (
    <Fragment>
      <Header />
      <Loader />
      <Location city="Paris, Belleville" />
      <Functioning />
      <Restaurants />
      <Footer />
    </Fragment>
  )
}

export default Home
