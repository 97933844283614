import { Route, Routes } from 'react-router-dom'
import Home from '../Home'
import Menu from '../Menu'
import ErrorPage from '../ErrorPage'

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  )
}

export default App
