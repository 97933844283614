import Logo from '../../assets/images/logo/ohmyfood@2x.svg'
import { Link } from 'react-router-dom'

const HeaderMenu = () => {
  return (
    <header className="header_page">
      <Link to="/">
        <i className="fas fa-arrow-left"></i>
      </Link>
      <img src={Logo} alt="Logo" />
    </header>
  )
}

export default HeaderMenu
