const Functioning = () => {
  return (
    <section className="functioning container">
      <h2>Fonctionnement</h2>
      <div className="functioning_list">
        <div className="functioning_tab">
          <div className="functioning_number">1</div>
          <div className="functioning_text">
            <i className="fas fa-mobile-alt"></i>
            Choisissez un restaurant
          </div>
        </div>

        <div className="functioning_tab">
          <div className="functioning_number">2</div>
          <div className="functioning_text">
            <i className="fas fa-list-ul"></i>
            Composez votre menu
          </div>
        </div>

        <div className="functioning_tab">
          <div className="functioning_number">3</div>
          <div className="functioning_text">
            <i className="fas fa-store"></i>
            Dégustez au restaurant
          </div>
        </div>
      </div>
    </section>
  )
}

export default Functioning
