const restaurants = [
  {
    name: 'La palette du goût',
    city: 'Ménilmontant',
    id: 1,
    img: '/img/restaurants/jay-wennington-N_Y88TWmGwA-unsplash.jpg',
    isNew: true,
  },
  {
    name: 'La note enchantée',
    city: 'Charonne',
    id: 2,
    img: '/img/restaurants/stil-u2Lp8tXIcjw-unsplash.jpg',
    isNew: true,
  },
  {
    name: 'À la française',
    city: 'Cité Rouge',
    id: 3,
    img: '/img/restaurants/toa-heftiba-DQKerTsQwi0-unsplash.jpg',
    isNew: false,
  },
  {
    name: 'Le délice des sens',
    city: 'Folie-Méricourt',
    id: 4,
    img: '/img/restaurants/louis-hansel-shotsoflouis-qNBGVyOCY8Q-unsplash.jpg',
    isNew: false,
  },
]

export default restaurants
