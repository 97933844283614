import MenuInfo from '../MenuInfo'

const MenuCategory = (props) => {
  const { title, menus } = props
  const orderedMenus = menus.sort((a, b) => {
    if (a.order > b.order) {
      return 1
    }
    if (a.order < b.order) {
      return -1
    }

    return 0
  })
  return (
    <div className="menu_list">
      <h3 className="title">{title}</h3>

      {orderedMenus.map((menu, index) => (
        <MenuInfo key={index} data={menu} id={'case-' + (index + 1)} />
      ))}
    </div>
  )
}

export default MenuCategory
