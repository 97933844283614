const Location = (props) => {
  return (
    <section>
      <div className="location">
        <p>
          <i className="fas fa-map-marker-alt"></i>
          {props.city}
        </p>
      </div>
      <div className="presentation">
        <h1>Réservez le menu qui vous convient</h1>
        <p>
          Découvrez des restaurants d'exception, sélectionnés par nos soins.
        </p>
        <div className="btn_presentation">
          <button className="btn btn--explore">Explorer nos restaurants</button>
        </div>
      </div>
    </section>
  )
}

export default Location
