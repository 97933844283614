import restaurant from '../../assets/data/restaurants'
import { Link } from 'react-router-dom'

const Restaurants = () => {
  const restaurantList = restaurant.map((data) => {
    return (
      <div className="restaurant_tab">
        <Link to={`/menu?id=${data.id}`}>
          <img src={data.img} alt={data.name} />
          <div className="restaurant_tab--content">
            <h3>{data.name}</h3>
            <p>{data.city}</p>
            {data.isNew && <span> Nouveau </span>}
          </div>
          <div className="restaurant_tab--heart">
            <i className="far fa-heart">
              <i className="fas fa-heart"></i>
            </i>
          </div>
        </Link>
      </div>
    )
  })

  return (
    <section className="restaurant">
      <h2>Restaurants</h2>
      <div className="restaurant_list container">{restaurantList}</div>
    </section>
  )
}

export default Restaurants
