const MenuInfo = (props) => {
  const { data } = props
  return (
    <div className="menu_tab">
      <div className="menu_text" id={props.id}>
        <h3>{data.name}</h3>
        <p>{data.subtitle}</p>
      </div>
      <div className="menu_price">{data.price}€</div>
      <div className="animation">
        <div className="anim_icon">
          <i className="fas fa-check-circle"></i>
        </div>
      </div>
    </div>
  )
}

export default MenuInfo
