import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <h3>ohmyfood</h3>
        <div>
          <ul>
            <li>
              <Link to="/">
                <i className="fas fa-utensils"></i>Proposer un restaurant
              </Link>
            </li>
            <li>
              <Link to="/">
                <i className="fas fa-hands-helping"></i> Devenir partenaire
              </Link>
            </li>
            <li>
              <Link to="/">Mentions légales</Link>
            </li>
            <li>
              <Link to="/">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
