import Logo from '../../assets/images/logo/ohmyfood@2x.svg'

const Header = () => {
  return (
    <header className="header_home">
      <img src={Logo} alt="Logo" />
    </header>
  )
}

export default Header
