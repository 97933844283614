import RestaurantName from '../RestaurantName'
import MenuCategory from '../MenuCategory'

const MenuContent = (props) => {
  const { data } = props
  let differentsTypeMenu = []

  data.menus.forEach((menu) => {
    differentsTypeMenu.push(menu.type)
    differentsTypeMenu = [...new Set(differentsTypeMenu)]
  })
  console.log(props)
  return (
    <section className="menu_img">
      <img src={data.img} alt={data.restaurantName} />

      <div className="menu_head">
        <RestaurantName>{data.restaurantName}</RestaurantName>
        <div className="menu container">
          {differentsTypeMenu.map((typeOfMenu, index) => (
            <MenuCategory
              key={index}
              title={typeOfMenu}
              menus={data.menus.filter((menu) => menu.type === typeOfMenu)}
              numberMenus={data.menus.length}
            />
          ))}
        </div>
      </div>
      <div className="com">
        <button className="btn">Commander</button>
      </div>
    </section>
  )
}

export default MenuContent
