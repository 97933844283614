const RestaurantName = ({ children }) => {
  return (
    <div className="menu_head--content container">
      <h2>{children}</h2>
      <i className="far fa-heart">
        <i className="fas fa-heart"></i>
      </i>
    </div>
  )
}

export default RestaurantName
